<template>
	<div id="app">
		<div class="menu1">
			<div class="left">
				<div class="logo" style="cursor: pointer;" @click="()=>{$router.push('/index')}">
					<img :src="log.src" />
					<div class="title">{{ log.title }}</div>
				</div>
				<div class="address">
					<img src="../assets/adres.png" style="margin-top: 5px;" />
					  <!-- <a-cascader    :default-value="value"	placeholder="请选择区域"  :show-search="{ filter }" :options="placeList" change-on-select @change="handleChange" /> -->
					<el-cascader
					  :value="place"
						:options="placeList"
						placeholder="请选择区域"
						:props="{ checkStrictly: true }"
						filterable
						@change="handleChange"
						clearable
					></el-cascader>
				</div>
			</div>
			<div class="center">
				<MenuList />
			</div>
			<div class="right">
				<div class="deng" @click="handleLogin">登录</div>
			</div>
		</div>
		<div class="content1">
			<router-view></router-view>
			<Footer />
		</div>
	</div>
</template>
<script>

import AMap from "AMap";
import { mapActions, mapState } from 'vuex'
import Footer from './components/footer.vue'
import MenuList from './components/menuList.vue'
export default {
	data() {
		return {

			}
		},
components: {
	Footer,
		MenuList
},
created() {
},
computed: {
     ...mapState(['log', 'placeList','placeArray','place','placeList1']),
},
mounted(){
	console.log(this.value)
	this.getLngLatLocation()
	this.handleplaceList()
	this.handlelog()
},
methods: {
			...mapActions(['handlelog', 'handleplaceList','handleBanners']),
		handleLogin(){
		window.open('http://gs.xcforest.com/')
	},
	handleChange(e){
		if (this.$route.path !== '/index') {
			this.$router.push('/index')
      this.handleBanners()
			this.$store.commit('setXuan', '/index')
				this.$store.commit('setPath','/index')
		}
		console.log(e)
		this.$store.commit('setplace', e[e.length-1])

	},
	filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
	 getLngLatLocation () {
		 console.log(this.place)
      AMap.plugin('AMap.CitySearch',()=>{
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity((status, result)=>{

          if (status === 'complete' && result.info === 'OK') {

			  console.log('通过ip获取当前城市：', result.city);
			  console.log(this.placeList1)
			  this.placeList1.forEach(res=>{
				  if(res.name==result.city){
					  console.log(res)
					  this.$store.commit('setplace',res.id)
				  }
			  })

          }
        });
      });
    }
}
	}
</script>

<style lang="less" >
html,
body {
	width: 100% !important;
	height: calc(100% - 90px) !important;
	padding: 0;
	margin: 0;
}

.ant-input ,
.ant-cascader-input,
.ant-cascader-input.ant-input ,
.ant-cascader-picker
 {
	border:none;

	background-color:transparent !important;
}
.ant-cascader-input.ant-input{
		border-bottom:1px solid #960;
		border-radius:0;
}
#app {
	width: 100%;

	// height:calc(100% - 90px);
	.menu1 {
		// background: yellow;
		background: url("../assets/top.png") no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 90px;
		position: fixed;
		top: 0;
		z-index: 999999;
		padding: 0 3%;
		display: flex;

		.left {
			width: 40%;
			height: 100%;
			// background: red;
			display: flex;
			justify-content: space-between;
			.logo {
				display: flex;
				align-items: center;

				img {
					width: 49px;
					height: 49px;
				}

				.title {
					color: #960;
					font-size: 24px;
					margin-left:10px
				}
			}

			.address {
				display: flex;
				height: 100%;
				align-items: center;
				// justify-content: center;
				img {
					width: 14px;
					height: 20px;
					margin-right: 10px;
				}

				select {
					border: none;
					border-bottom: 1px solid #960;
					background-color: rgba(255, 255, 255, 0);
				}
			}
			.el-cascader {
				border: none;
			}
			.el-input__inner {
				border: none;
				background: rgba(255, 255, 255, 0);
				border-bottom: 1px solid #960;
				border-radius: 0px;
			}
			.el-cascader-panel {
			}
		}

		.center {
			width: 57%;
			height: 100%;
			// background: blue;
			display: flex;
			// align-items: center;
			justify-content: center;
		}

		.right {
			width: 3%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.ll {
				display: flex;

				.yuab {
					width: 25px;
					height: 25px;
					border: 1px solid #999999;
					border-radius: 50%;
					font-size: 14px;
					color: #999;
					line-height: 23px;
					text-align: center;
				}

				.index {
					border: 1px solid #960;
					color: #960;
				}
			}

			.deng {
				width: 59px;
				height: 25px;
				border: 1px solid #996600;
				opacity: 1;
				color: #960;
				border-radius: 25px;
				line-height: 23px;
				text-align: center;
				cursor:pointer;
			}
		}
	}

	.content1 {
		margin-top: 90px;
		width: 100%;
		// height:calc(100% - 90px);
	}
}
@media (max-width: 1679px) {
	// #app .menu1 .left {
	// 	width: 40%;
	// }
	// #app .menu1 .center {
	// 	width: 52%;
	// }
}
@media (max-width: 1599px) {
	// #app .menu1 .left {
	// 	width: 45%;
	// }
	// #app .menu1 .center {
	// 	width: 47%;
	// }
}
</style>
